
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { ApiView } from '../../../../components/apiView';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import { apis } from './apis';
import CheckableExample from './examples/CheckableExample.vue';
import CheckableExampleCode from '!!raw-loader!./examples/CheckableExample.vue';
import CustomCellsExample from './examples/CustomCellsExample.vue';
import CustomCellsExampleCode from '!!raw-loader!./examples/CustomCellsExample.vue';
import CustomRowsExample from './examples/CustomRowsExample.vue';
import CustomRowsExampleCode from '!!raw-loader!./examples/CustomRowsExample.vue';
import MultipleSortingExample from './examples/MultipleSortingExample.vue';
import MultipleSortingExampleCode from '!!raw-loader!./examples/MultipleSortingExample.vue';
import PaginationAndSortingExample from './examples/PaginationAndSortingExample.vue';
import PaginationAndSortingExampleCode from '!!raw-loader!./examples/PaginationAndSortingExample.vue';
import SelectionExample from './examples/SelectionExample.vue';
import SelectionExampleCode from '!!raw-loader!./examples/SelectionExample.vue';
import SimpleExample from './examples/SimpleExample.vue';
import SimpleExampleCode from '!!raw-loader!./examples/SimpleExample.vue';

export default defineComponent({
	name: 'table-documentation',
	components: {
		ExampleView,
		ApiView,
		SimpleExample,
		BHorizontalDivider,
		CustomRowsExample,
		CustomCellsExample,
		SelectionExample,
		CheckableExample,
		PaginationAndSortingExample,
		MultipleSortingExample
	},
	setup() {
		return {
			apis,
			SimpleExampleCode,
			PaginationAndSortingExampleCode,
			CheckableExampleCode,
			CustomCellsExampleCode,
			CustomRowsExampleCode,
			SelectionExampleCode,
			MultipleSortingExampleCode
		};
	}
});

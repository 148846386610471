
import { BSwitch } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import BTable from 'buetify/lib/components/table/BTable';
import { BTableColumn } from 'buetify/lib/components/table/shared';
import BTag from 'buetify/lib/components/tag/BTag';
import { defineComponent, shallowRef, computed } from 'vue';
import FemaleIcon from '../../../../../components/icons/FemaleIcon';
import MaleIcon from '../../../../../components/icons/MaleIcon';
import { people, Person } from '../../../shared/data';

const rows: Person[] = people.slice(0, 5);

interface PeopleTableColumn extends BTableColumn<Person> {}

const columns: PeopleTableColumn[] = [
	{
		value: 'id',
		label: 'ID',
		width: '40'
	},
	{
		value: row => row.user.firstName,
		label: 'First Name'
	},
	{
		value: row => row.user.lastName,
		label: 'Last Name'
	},
	{
		value: 'date',
		label: 'Date',
		slotName: 'date',
		position: 'is-centered'
	},
	{
		value: 'gender',
		slotName: 'gender',
		label: 'Gender'
	}
];

export default defineComponent({
	name: 'custom-rows-table-example',
	components: {
		BField,
		BSwitch,
		BTable,
		BTag,
		MaleIcon,
		FemaleIcon
	},
	setup() {
		const isBordered = shallowRef(false);
		const isStriped = shallowRef(false);
		const isNarrow = shallowRef(false);
		const isHoverable = shallowRef(false);
		const isLoading = shallowRef(false);
		const isEmpty = shallowRef(false);
		const useMobileCards = shallowRef(false);

		const newRows = computed(() => (isEmpty.value ? [] : rows));

		return {
			rows: newRows,

			columns,
			isBordered,
			isStriped,
			isNarrow,
			isHoverable,
			isLoading,
			isEmpty,
			useMobileCards
		};
	}
});
